import { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  List,
  ListItem,
  Popover,
  Link,
} from '@mui/material';
import Flag from 'react-flagpack';
import { WizardStep } from 'component/base/WizardStep';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { TextField, Options } from 'component/base/TextField';
import isEmail from 'validator/lib/isEmail';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ToggleSettings from 'component/view/SiteDetails/Advanced/components/SiteSettings/ToggleSetting';
import { useCreatePartnerSite, useCreateSite, useSiteTemplates } from 'api/site';
import { settingsItem, settingsGrid, settingsIcon } from 'theme/custom/settings';
import { useNavigate } from 'react-router-dom';
import { isManagedHosting, useGetToken } from 'utils/token';
import { useLocations, Location } from 'api/locations';
import { lighten } from '@mui/material/styles';
import { linkHelper } from 'linkHelper';
import { generatePassword } from 'utils/password';
import { createStaticPaginatedListState } from 'component/hooks/usePaginatedListState';

const PREFIX = 'CreateSite';

const classes = {
  typographyLabel: `${PREFIX}TypographyLabel`,
  textField: `${PREFIX}TextField`,
  generateButton: `${PREFIX}GenerateButton`,
  generateButtonAdornment: `${PREFIX}GenerateButtonAdornment`,
  settingsItem: `${PREFIX}SettingsItem`,
  settingsGrid: `${PREFIX}SettingsGrid`,
  settingsIcon: `${PREFIX}SettingsIcon`,
};

const Root = styled('main')({
  [`& .${classes.typographyLabel}`]: {
    marginBottom: '0.3125rem',
    '& button': {
      margin: '-0.4375rem 0.5rem -0.4375rem 0',
    },
  },
  [`& .${classes.generateButton}`]: {
    lineHeight: '1em',
    opacity: 1,
  },
  [`& .${classes.generateButtonAdornment}`]: {
    opacity: 1,
  },
  [`& .${classes.settingsItem}`]: {
    ...settingsItem,
    paddingBottom: '0.375rem',
    paddingTop: '0.375rem',
  },
  [`& .${classes.settingsGrid}`]: {
    ...settingsGrid,
  },
  [`& .${classes.settingsIcon}`]: {
    ...settingsIcon,
  },
  [`& .${classes.textField}`]: {
    '&:not(:last-child)': {
      marginBottom: '1.25rem',
    },
    '& label': {
      display: 'block',
      marginBottom: '0.3125rem',
    },
    '& p': {
      marginTop: '0.3125rem',
    },
  },
  [`& > * > .MuiCardContent-root`]: {
    padding: '0 !important',
  },
});

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

interface SiteDetailsFormValue {
  siteName: string;
  location: string;
  isRestrictedLocation: boolean;
  templateId: string;
}

interface SiteDetailsProps {
  readonly onCompleted: (value: SiteDetailsFormValue) => unknown;
}

const SiteDetails = ({ onCompleted }: SiteDetailsProps) => {
  const { t } = useTranslation();
  const qsParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const templateId = qsParams.get('templateId');

  const methods = useForm<SiteDetailsFormValue>({
    defaultValues: {
      siteName: '',
      location: '-1',
      templateId: typeof templateId === 'string' ? templateId : '-1',
      isRestrictedLocation: false,
    },
    mode: 'onChange',
  });

  const { data: siteTemplatesData, isLoading } = useSiteTemplates(createStaticPaginatedListState());
  const { handleSubmit, formState, setValue } = methods;
  const { data } = useLocations();
  const locations = data?.data.result.locations ?? [];
  const enterpriseLocatons = data?.data.result.restricted_locations ?? [];

  const enterpriseLocatonIds = enterpriseLocatons.map(location => String(location.id));

  const mapLocation = ({ id, location }: Location) => {
    const locationSplit = location.split(' - ');
    return {
      value: String(id),
      label: (
        <>
          <Flag code={locationSplit[0]} size="m" />
          <Box marginLeft={1}>{location}</Box>
        </>
      ),
    };
  };

  let options: Options = [];
  if (enterpriseLocatons?.length) {
    options = [
      {
        sectionName: t('enterprise_locations'),
        options: enterpriseLocatons.map(mapLocation),
      },
      {
        sectionName: t('standard_locations'),
        options: locations.map(mapLocation),
      },
    ];
  } else {
    options = locations.map(mapLocation) ?? [];
  }

  options.unshift({
    value: '-1',
    label: t('choose_location'),
  });

  const onSubmit = async (data: SiteDetailsFormValue) => {
    onCompleted(data);
  };

  const templateOptions =
    siteTemplatesData?.data.result?.site_templates?.map(template => ({
      value: template.template_id ?? '',
      label: template.name ?? '',
    })) ?? [];

  templateOptions.unshift({
    value: '-1',
    label: t('select_template'),
  });

  const noTemplatesAvailable = !isLoading && templateOptions.length === 1;

  return (
    <Card>
      <CardHeader title={t('create_site_wizard_step_one_card_title')} />
      <CardContent>
        <Box marginBottom={2}>
          <Typography className={classes.typographyLabel}>
            {t('create_site_wizard_step_one_form_title')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={classes.textField}>
                  <TextField
                    name="siteName"
                    label={t('site_name_label')}
                    placeholder={t('site_name_label_placeholder')}
                    fullWidth
                    rules={{
                      required: true,
                    }}
                    endNode={
                      <Typography variant="body2">{t('site_name_label_description')}</Typography>
                    }
                  />
                </Box>
                <TextField
                  name="location"
                  label={t('select_location')}
                  fullWidth
                  options={options}
                  handleSelect={value => {
                    setValue('isRestrictedLocation', enterpriseLocatonIds.includes(value));
                  }}
                  rules={{
                    required: true,
                    validate: (val: string) => {
                      if (val === '-1') {
                        return 'Select a location';
                      }
                    },
                  }}
                />
                <TextField
                  name="templateId"
                  label={t('build_from_template')}
                  fullWidth
                  options={templateOptions}
                  handleSelect={value => {
                    setValue('templateId', value);
                  }}
                  isLoading={isLoading}
                  disabled={noTemplatesAvailable}
                />
                <Button
                  disabled={!formState.isValid || isLoading}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                >
                  {t('continue')}
                </Button>
                {!!noTemplatesAvailable && (
                  <Box marginTop={2}>
                    <Card
                      elevation={0}
                      sx={theme => ({
                        backgroundColor: lighten(theme.palette.primary.light, 0.8),
                      })}
                    >
                      <CardContent>
                        <Typography variant="body1" fontWeight="bold">
                          {t('create_template_site_create_title')}
                        </Typography>
                        <Typography variant="body1" color="gray">
                          <Trans
                            i18nKey="create_template_site_create_description"
                            components={{
                              siteTemplatesLink: (
                                <Link
                                  onClick={() => {
                                    navigate(linkHelper.sites.templates.getLink());
                                  }}
                                />
                              ),
                            }}
                          />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                )}
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

interface WordpressInformationProps {
  readonly onCompleted: (data: WordpressInfoFormValue) => Promise<unknown>;
  readonly siteDetails: SiteDetailsFormValue | null;
  readonly isSubmitting: boolean;
}

type GeneratePasswordFormValue = {
  length: number;
  password: string;
};

const GeneratePasswordDialog = ({
  setPassword,
  onClose,
}: {
  readonly setPassword: (value: string) => unknown;
  readonly onClose: () => unknown;
}) => {
  const methods = useForm<GeneratePasswordFormValue>({
    defaultValues: {
      length: 12,
      password: generatePassword(),
    },
    mode: 'onChange',
  });
  const { handleSubmit, setValue, getValues } = methods;
  const { t } = useTranslation();

  const onSubmit = (values: GeneratePasswordFormValue) => {
    copyTextToClipboard(values.password);
    setPassword(values.password);
    onClose();
  };

  return (
    <ConfirmationDialog
      action="confirm"
      description={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.textField}>
              <TextField
                name="length"
                label={t('password_length')}
                fullWidth
                rules={{ required: true }}
                onChange={event => {
                  setValue('password', generatePassword(Number(event.target.value) || 10));
                }}
              />
            </Box>
            <Box className={classes.textField}>
              <TextField
                name="password"
                fullWidth
                label={t('generated_password')}
                rules={{ required: true }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    disableTypography
                    className={classes.generateButtonAdornment}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={e => {
                        setValue('password', generatePassword(getValues().length));
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className={classes.generateButton}
                    >
                      {t('regenerate')}
                    </Button>
                  </InputAdornment>
                }
              />
            </Box>
          </form>
        </FormProvider>
      }
      id="createFtpAccountModal"
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
      open
      title={t('generate_password')}
      confirmText={t('copy_to_clipboard_and_insert')}
    />
  );
};

interface WordpressInfoFormValue extends SiteDetailsFormValue {
  username: string;
  password: string;
  email: string;
  multisite: boolean;
  preferredLanguage: string;
  atarim: boolean;
  wooCommerce: boolean;
  wpRecommendedPlugins: boolean;
}

const WordpressInformation = ({
  siteDetails,
  onCompleted,
  isSubmitting,
}: WordpressInformationProps) => {
  const { t } = useTranslation();
  const methods = useForm<WordpressInfoFormValue>({
    defaultValues: {
      ...siteDetails,
      username: '',
      password: '',
      email: '',
      preferredLanguage: 'english',
      atarim: false,
      multisite: false,
      wooCommerce: false,
      wpRecommendedPlugins: siteDetails?.templateId !== '-1' ? false : true,
    },
    mode: 'onChange',
  });
  const [showGeneratePasswordDialog, setShowGeneratePasswordDialog] = useState<boolean>(false);

  const { handleSubmit, formState, setValue, getValues, watch } = methods;

  const onSubmit = async (data: WordpressInfoFormValue) => {
    await onCompleted(data);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [noOptionsAnchorEl, setNoOptionsAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hasTemplateId = watch('templateId') !== '-1';

  return (
    <>
      {showGeneratePasswordDialog ? (
        <GeneratePasswordDialog
          setPassword={value => setValue('password', value)}
          onClose={() => setShowGeneratePasswordDialog(false)}
        />
      ) : null}
      <Card>
        <CardHeader title={t('create_site_wizard_step_two_card_title')} />
        <CardContent>
          <Box marginBottom={2}>
            <Typography className={classes.typographyLabel}>
              {t('create_site_wizard_step_two_form_title')}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box className={classes.textField}>
                    <TextField
                      disabled={isSubmitting}
                      name="username"
                      label={t('wordpress_username')}
                      placeholder={t('wordpress_username_placeholder')}
                      fullWidth
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                  <Box className={classes.textField}>
                    <TextField
                      disabled={isSubmitting}
                      name="password"
                      label={t('wordpress_password')}
                      fullWidth
                      type="password"
                      rules={{
                        required: true,
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          disableTypography
                          className={classes.generateButtonAdornment}
                        >
                          <Button
                            color="primary"
                            variant="text"
                            disabled={isSubmitting}
                            onClick={() => setShowGeneratePasswordDialog(true)}
                            className={classes.generateButton}
                          >
                            {t('generate')}
                          </Button>
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box className={classes.textField}>
                    <TextField
                      disabled={isSubmitting}
                      name="email"
                      label={t('wordpress_email')}
                      fullWidth
                      rules={{
                        validate: email => {
                          return isEmail(email);
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textField}>
                    <TextField
                      disabled={isSubmitting}
                      name="preferredLanguage"
                      label={t('language')}
                      fullWidth
                      options={[
                        {
                          value: 'english',
                          label: t('english'),
                        },
                      ]}
                    />
                  </Box>
                  <Box marginBottom={1}>
                    <Typography>
                      <strong>{t('additional_options')}</strong>
                      {hasTemplateId ? (
                        <>
                          <IconButton
                            aria-describedby="wpRecommendedPlugins"
                            onClick={event => setNoOptionsAnchorEl(event.currentTarget)}
                            color="primary"
                            size="large"
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                          <Popover
                            id="noOptionsAllowed"
                            anchorEl={noOptionsAnchorEl}
                            open={Boolean(noOptionsAnchorEl)}
                            onClose={() => setNoOptionsAnchorEl(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Typography>
                              {t('create_site_using_a_template_no_options_warning')}
                            </Typography>
                          </Popover>
                        </>
                      ) : null}
                    </Typography>
                    {t('additional_options_details')}
                  </Box>
                  <Box marginBottom={2}>
                    <List>
                      <ListItem className={classes.settingsItem}>
                        <Grid container spacing={2} className={classes.settingsGrid}>
                          <Grid item xs={12} md={9}>
                            <Typography>{t('multisite_support')}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <ToggleSettings
                              disabled={isSubmitting || hasTemplateId}
                              onChange={async value => {
                                setValue('multisite', value.value === 1);
                                return;
                              }}
                              name="multisite_support"
                              value={getValues().multisite}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem className={classes.settingsItem}>
                        <Grid container spacing={2} className={classes.settingsGrid}>
                          <Grid item xs={12} md={9}>
                            <Typography>{t('woocommerce')}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <ToggleSettings
                              disabled={isSubmitting || hasTemplateId}
                              onChange={async value => {
                                setValue('wooCommerce', value.value === 1);
                                return;
                              }}
                              name="woo_commerce"
                              value={getValues().wooCommerce}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem className={classes.settingsItem}>
                        <Grid container spacing={2} className={classes.settingsGrid}>
                          <Grid item xs={12} md={9}>
                            <Typography>{t('atarim_collaborate')}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <ToggleSettings
                              disabled={isSubmitting || hasTemplateId}
                              onChange={async value => {
                                setValue('atarim', value.value === 1);
                                return;
                              }}
                              name="atarim"
                              value={getValues().atarim}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem className={classes.settingsItem}>
                        <Grid container spacing={2} className={classes.settingsGrid}>
                          <Grid item xs={12} md={9}>
                            <Typography>
                              {t('wp_recommended_plugins')}
                              {!hasTemplateId ? (
                                <>
                                  <IconButton
                                    aria-describedby="wpRecommendedPlugins"
                                    onClick={handleHelpClick}
                                    color="primary"
                                    size="large"
                                  >
                                    <HelpOutlineIcon />
                                  </IconButton>
                                  <Popover
                                    id="productionVsStaging"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                  >
                                    <Typography>{t('wp_recommended_plugins_help')}</Typography>
                                    <ul style={{ listStyle: 'inside' }}>
                                      <li>{t('wp_recommended_plugins_help_item_1')}</li>
                                      <li>{t('wp_recommended_plugins_help_item_2')}</li>
                                      <li>{t('wp_recommended_plugins_help_item_3')}</li>
                                      <li>{t('wp_recommended_plugins_help_item_4')}</li>
                                      <li>{t('wp_recommended_plugins_help_item_5')}</li>
                                      <li>{t('wp_recommended_plugins_help_item_6')}</li>
                                    </ul>
                                  </Popover>
                                </>
                              ) : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <ToggleSettings
                              disabled={isSubmitting || hasTemplateId}
                              onChange={async value => {
                                setValue('wpRecommendedPlugins', value.value === 1);
                                return;
                              }}
                              name="wp_recommended_plugins"
                              value={getValues().wpRecommendedPlugins}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Box>
                  <ProgressiveButton
                    disabled={!formState.isValid || isSubmitting}
                    onClick={() => {
                      handleSubmit(onSubmit)();
                    }}
                    text={t('continue')}
                    variant="contained"
                    color="primary"
                    isLoading={isSubmitting}
                  />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

function CreateSiteContent() {
  const initialActiveStep = 1;
  const { t } = useTranslation();
  const token = useGetToken();
  const isManaged = isManagedHosting(token);
  const createPartnerSite = useCreatePartnerSite();
  const createSite = useCreateSite();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<number>(initialActiveStep);
  const [siteDetailsData, setSiteDetailsData] = useState<SiteDetailsFormValue | null>(null);

  function getActiveStepBody() {
    switch (activeStep) {
      case 1:
        return (
          <SiteDetails
            onCompleted={data => {
              setSiteDetailsData(data);
              setActiveStep(2);
            }}
          />
        );
      case 2:
        return (
          <WordpressInformation
            siteDetails={siteDetailsData}
            isSubmitting={createPartnerSite.isPending || createSite.isPending}
            onCompleted={async data => {
              const installedPlugins = [];

              if (data.wooCommerce) {
                installedPlugins.push('woocommerce');
              }

              if (data.wpRecommendedPlugins) {
                installedPlugins.push('coming-soon');
                installedPlugins.push('google-site-kit');
                installedPlugins.push('optinmonster');
                installedPlugins.push('wpforms-lite');
                installedPlugins.push('wp-mail-smtp');
                installedPlugins.push('all-in-one-seo-pack');
              }

              if (data.atarim) {
                installedPlugins.push(
                  'https://atarim.io/wp-content/uploads/atarim-client-interface-wpplugin-3.2.1.zip'
                );
              }

              if (isManaged) {
                const response = await createSite.mutateAsync({
                  name: data.siteName,
                  label: data.siteName,
                  admin_username: data.username,
                  admin_password: data.password,
                  admin_email: data.email,
                  location: !data.isRestrictedLocation ? Number(data.location) : undefined,
                  restricted_location: data.isRestrictedLocation
                    ? Number(data.location)
                    : undefined,
                  multisite: data.multisite,
                  template_id: data.templateId !== '-1' ? data.templateId : undefined,
                  install_plugins:
                    data.templateId !== '-1' ? undefined : installedPlugins.join(','),
                });
                navigate(`/sites/${response.data?.result?.id ?? ''}`);
              } else {
                const response = await createPartnerSite.mutateAsync({
                  name: data.siteName,
                  label: data.siteName,
                  admin_username: data.username,
                  admin_password: data.password,
                  admin_email: data.email,
                  location: !data.isRestrictedLocation ? Number(data.location) : undefined,
                  restricted_location: data.isRestrictedLocation
                    ? Number(data.location)
                    : undefined,
                  multisite: data.multisite,
                  template_id: data.templateId !== '-1' ? data.templateId : undefined,
                  install_plugins:
                    data.templateId !== '-1' ? undefined : installedPlugins.join(','),
                  domain: 'wpdns.site',
                });
                navigate(`/sites/${response.data.result?.id}`);
              }
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <WizardStep
            activeStep={activeStep}
            step={1}
            title={t('create_site_wizard_step_one_title')}
            description={t('create_site_wizard_step_one_description')}
          />
          <WizardStep
            activeStep={activeStep}
            step={2}
            title={t('create_site_wizard_step_two_title')}
            description={t('create_site_wizard_step_two_description')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          {getActiveStepBody()}
        </Grid>
      </Grid>
    </CardContent>
  );
}

export const CreateSite = () => {
  const { t } = useTranslation();

  return (
    <Root id="mainContent">
      <Container maxWidth="lg">
        <Box marginBottom={3}>
          <Typography variant="h1" data-testid="domainLabel">
            {t('create_new_type', {
              type: t('site'),
            })}
          </Typography>
        </Box>
        <CreateSiteContent />
      </Container>
    </Root>
  );
};
